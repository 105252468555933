export type AdType =
  | "billboard"
  | "leaderboard"
  | "double_mpu"
  | "mobile_banner"
  | "mobile_mpu"
  | "skyscraper_left"
  | "skyscraper_right"
  | "horizontal_sticky"
  | "mobile_horizontal_sticky";

export const adData: Record<AdType, { className: string }> = {
  billboard: {
    className: "h-[250px] w-[970px]",
  },
  leaderboard: {
    className: "h-[90px] w-[728px]",
  },
  double_mpu: {
    className: "h-[600px] w-[300px] flex justify-center items-start",
  },
  mobile_banner: {
    className: "h-[100px] w-[320px]",
  },
  mobile_mpu: {
    className: "h-[250px] w-[300px]",
  },
  // We need two one for each side
  skyscraper_left: {
    className: "",
  },
  skyscraper_right: {
    className: "",
  },
  horizontal_sticky: {
    className: "fixed bottom-0 left-1/2 transform -translate-x-1/2 h-[90px] max-w-[728px] w-full z-[10000]",
  },
  mobile_horizontal_sticky: {
    className: "fixed bottom-0 left-1/2 transform -translate-x-1/2 h-[90px] max-w-[341px] w-full z-[10000]",
  },
};
