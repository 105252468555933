/* eslint-disable tailwindcss/no-custom-classname */
/* eslint @typescript-eslint/no-unsafe-call: 0 */
/* eslint @typescript-eslint/no-unsafe-member-access: 0 */
/* eslint @typescript-eslint/no-unsafe-assignment: 0 */
import classNames from "classnames";
import { useEffect, useRef } from "react";
import { AdType, adData } from "./constants";

interface AdContainerProps {
  type: AdType;
  className?: string;
}

export const AdContainer = ({ type, className }: AdContainerProps) => {
  const adRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const self = window.self as any;
    if (!self) return;

    self.__vm_add = self.__vm_add || [];

    return () => {
      if (!self) return;

      self.__VM = self.__VM || [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      self.__VM.push(function (admanager: any, scope: any) {
        scope.Config.autoPlacements().recover();
      });
    };
  }, []);

  return (
    <div className={classNames(className, adData[type].className)}>
      <div className={`vm-placement ${type}`} ref={adRef} />
    </div>
  );
};
