import classNames from "classnames";
import { AdContainer } from "./AdContainer";

/**
  This component is used to display sticky side rail ads on the left and right side of the page slot.
*/
export const StickySideRailAds = ({
  className,
  itemsWrapperClassName,
}: {
  className?: string;
  itemsWrapperClassName?: string;
}) => {
  return (
    <div className={classNames("top-nav sticky h-0 w-full overflow-x-clip max-xl:hidden", className)}>
      <div className={classNames("relative mx-auto h-0 w-full max-w-8xl", itemsWrapperClassName)}>
        <AdContainer type="skyscraper_left" className="absolute left-0 top-0 translate-x-[-110%]" />
        <AdContainer type="skyscraper_right" className="absolute right-0 top-0 translate-x-[110%]" />
      </div>
    </div>
  );
};
